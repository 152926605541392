import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'

// Featured Slider
var feature_slider = new Swiper('.swiper-container', {
    direction: 'horizontal',
    autoHeight: false,
    loop: true,
    watchOverflow: true,
    autoplay: {
      delay: 4000,
    },
    effect: 'slide',
    pagination: {
      el: '.content-slider-controls',
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '">0' + (index + 1) + '</span>';
      },
    },
});
// Wine Slider
var wine_content_slider = new Swiper('.wine-swiper-container', {
  direction: 'horizontal',
  autoHeight: false,
  loop: true,
  watchOverflow: true,
  autoplay: {
    delay: 4000,
  },
  effect: 'slide',
  // slidesPerView: 3,
  // spaceBetween: 0,
  breakpoints: {
    '@0.00': {
      slidesPerView: 1,
      spaceBetween: 0
    },
    '@0.50': {
      slidesPerView: 1,
      spaceBetween: 0
    },
    '@0.70': {
      slidesPerView: 3,
      spaceBetween: 0
    },
    '@0.80': {
      slidesPerView: 3,
      spaceBetween: 0
    },
    '@0.85': {
      slidesPerView: 3,
      spaceBetween: 0
    },
    '@0.90': {
      slidesPerView: 3,
      spaceBetween: 0
    },
    '@1.00': {
      slidesPerView: 3,
      spaceBetween: 0
    },
  },
  centeredSlides: true,
  navigation: {
    nextEl: '.swiper-next',
    prevEl: '.swiper-prev',
  },
});